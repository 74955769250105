import { Controller as BaseController } from "@hotwired/stimulus"

const countTag = document.createElement("span");

export class Controller extends BaseController {
  // TODO: resolve error loader to be able to call static targets = [ "dropdown"]
  static get targets() {
    return [ "button", "dropdown", "checkbox", "filtersCheckedCount", "filtersdropdown", "submit", "loader" ]
  }

  connect() {
    this.countFilters()

  }

  setAllCheckboxes(checked) {
    this.checkboxTargets.forEach((el) => {
      const checkbox = el;

      if (!checkbox.disabled) {
        checkbox.checked = checked;
      }
    })
  }

  checkNone() {
    this.setAllCheckboxes(false)
    this.filtersCheckedCountTargets.forEach(el => {
      el.classList.add("hidden")
    })
    this.displayLoad()
    const form = this.buttonTarget.closest('form')
    form.submit()
  }

  toggleDropdown() {
    event.preventDefault()
    this.dropdownTarget.classList.toggle('hidden')
    this.buttonTarget.classList.toggle('border-blue-200')
    this.buttonTarget.classList.toggle('border-gray-200')
    this.buttonTarget.classList.toggle('hover:border-blue-200')
    this.buttonTarget.classList.toggle('hover:border-grey-300')
    this.buttonTarget.classList.toggle('text-gray-900')
    this.buttonTarget.firstChild.nextElementSibling.classList.toggle('text-blue-800')
    document.body.classList.toggle("max-md:fixed")
  }

  clickOutside(e) {
    if(this.element === e.target || this.element.contains(e.target)) return
    if(!this.dropdownTarget.classList.contains("hidden")) {
      this.toggleDropdown()
    }
  }

  countFilters() {
    this.buttonTarget.appendChild(countTag)
    const countTagClasses = [ "hidden", "flex", "items-center", "justify-center", "bg-blue-800", "rounded-full", "ml-2", "w-5", "h-5", "text-white", "text-xs", "font-semibold" ]
    countTag.classList.add(...countTagClasses)

    const checkboxesChecked = this.checkboxTargets.filter((c) => c.checked)
    const allCheckboxesChecked = []
    allCheckboxesChecked.push(checkboxesChecked.length)
    countTag.innerHTML = allCheckboxesChecked

    if (checkboxesChecked.length > 0)  {
      countTag.classList.remove("hidden")
    } else {
      countTag.classList.add("hidden")
    }
  }

  displayLoad() {
    event.preventDefault()
    const hideValueSubmitClasses = [ "text-blue-800", "hover:text-blue-900" ]
    this.submitTarget.classList.remove("text-white")
    this.submitTarget.classList.add(...hideValueSubmitClasses)
    this.loaderTarget.classList.remove("hidden")
    const form = this.buttonTarget.closest('form')
    form.submit()
  }
}
