// Add a Stimulus controller for this component.
// It will automatically registered and its name will be available
// via #component_name in the component class.
//
import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  // TODO: resolve error loader to be able to call static targets = [ "dropdown"]
  static get targets() {
    return [ "dropdown" ]
  }

  toggleDropdown() {
    this.dropdownTarget.classList.toggle('hidden')
  }

  clickOutside(e) {
    if(this.element === e.target || this.element.contains(e.target)) return;

    if(!this.dropdownTarget.classList.contains("hidden")) {
      this.toggleDropdown()
    }
  }
}
