import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "title", "option", "inputHidden", "headOption", "list" ]

  toggleList() {
    const arrow = this.titleTarget.querySelector('i')
    arrow.classList.toggle('rotate-180')
    this.listTarget.classList.toggle('hidden')
  }

  selectOption(e) {
    e.stopImmediatePropagation()
    const parameters = e.params
    const selectedClasses = ["text-white", "bg-blue-800", "hover:!bg-blue-800"]

    this.optionTargets.map((option) => {
      option.classList.remove(...selectedClasses)
    });

    if (e.target !== this.headOptionTarget) {
      e.target.classList.add(...selectedClasses)
    }

    this.titleTarget.querySelector('span').innerHTML = e.target.innerHTML

    this.toggleList()
    this.fillInput(parameters)
  }

  fillInput(params) {
    if (params.value || params.value === '') {
      this.inputHiddenTarget.setAttribute('value', params.value)
    }
  }

  clickOutside(e) {
    if(this.element === e.target || this.element.contains(e.target)) return;

    if(!this.listTarget.classList.contains("hidden")) {
      this.toggleList()
    }
  }
}
