import { Controller } from "@hotwired/stimulus"

const activeClasses = ["bg-indigo-50", "before:bg-blue-800", "hover:text-gray-500"]

export default class extends Controller {
  static targets = [ "tab", "content" ]

  connect() {
    this.tabTargets[0].classList.add(...activeClasses)
    this.contentTargets.map((content, i) => {
      if (i !== 0) {
        content.classList.add('hidden')
      }
    });
  }

  activeTab(e) {
    e.preventDefault()
    let activeTab

    e.target.tagName === 'BUTTON'
      ? activeTab = e.target
      : activeTab = e.target.parentNode

    this.tabTargets.map((tab, i) => {
      if (tab !== activeTab) {
        tab.classList.remove(...activeClasses)
        this.contentTargets[i].classList.add('hidden')
      } else {
        activeTab.classList.add(...activeClasses)
        this.contentTargets[i].classList.remove('hidden')
      }
    });
  }
}



