import { Controller as BaseController } from "@hotwired/stimulus";
import { turboRequest } from "../../../../../../javascript/customer_web/turbo_request";

let debounce = require("lodash/debounce");

export class Controller extends BaseController {
  static get targets() {
    return ["loader"];
  }

  static get values() {
    return {
      variantId: Number,
      quantity: Number,
    };
  }

  initialize() {
    this.updateQuantity = debounce(this.updateQuantity, 400).bind(this);
  }

  updateQuantity() {
    this.toggleLoader();

    fetch(
      turboRequest("/cw/cart", "PUT", {
        variant_id: this.variantIdValue,
        quantity: this.quantityValue,
      })
    )
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .then(() => this.toggleLoader());
  }

  increaseQuantity() {
    this.quantityValue++;
  }

  decreaseQuantity() {
    if (this.quantityValue > 0) this.quantityValue--;
  }

  toggleLoader() {
    this.loaderTarget.classList.toggle("hidden");
  }
}
