// Add a Stimulus controller for this component.
// It will automatically registered and its name will be available
// via #component_name in the component class.
//
import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
    // TODO: resolve error loader to be able to call static targets = [ "dropdown"]
    static get targets() {
      return [ "attributes" ]
    }

  connect() {
    // Google Tag Manager
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-M7KMXTJ');
    // End Google Tag Manager

    const dataAttributes =  this.attributesTarget.dataset.datalayerAttributes;
    const dataAttributesArray = JSON.parse(dataAttributes);

    dataLayer =  dataAttributesArray;
  }
}
