import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  // TODO: resolve error loader to be able to call static targets = [ "dropdown"]
  static get targets() {
    return [ "menuIcon", "closeIcon", "menu", "logo" ]
  }

  connect() {

  }

  toggleMenu() {
    this.menuIconTarget.classList.toggle("hidden")
    this.closeIconTarget.classList.toggle("hidden")
    this.menuTarget.classList.toggle("hidden")
    this.logoTarget.classList.toggle("opacity-0")

    const title = document.getElementById("pageTitle")
    title.classList.toggle("hidden")
  }
}
