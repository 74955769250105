import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  // TODO: resolve error loader to be able to call static targets = [ "dropdown"]
  static get targets() {
    return [ "closeButton", "submit", "loader", "scrollTarget" ]
  }

  closeModal() {
    this.element.parentElement.removeAttribute("src")
    this.element.remove()
    document.body.classList.remove("h-full")
    document.body.classList.remove("overflow-hidden")
  }

  clickOutside(e) {
    if (this.element === e.target) {
      this.closeModal()
    }
  }

  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.closeModal()
    }
  }

  handleSubmission(e) {
    if (e.detail.success) {
      this.closeModal()
    }
  }

  displayLoad() {
    const hideValueSubmitClasses = [ "text-blue-800", "hover:text-blue-900" ]
    this.submitTarget.classList.remove("text-white")
    this.submitTarget.classList.add(...hideValueSubmitClasses)
    this.loaderTarget.classList.remove("hidden")
  }

  keepScrollPosition() {
    const targetPosition = document.documentElement.scrollTop

      addEventListener("turbo:frame-render", function(){
        const modal = document.getElementById("modal").firstChild.nextSibling
        modal.style.top = `${targetPosition}px`
        document.body.classList.add("h-full")
        document.body.classList.add("overflow-hidden")

    })
  }
}
