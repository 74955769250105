import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button", "dropdown", "arrow" ]

  toggleDropdown() {
    this.dropdownTarget.classList.toggle('hidden')

    if (this.hasArrowTarget) {
      this.arrowTarget.classList.toggle('rotate-180')
    }
  }

  clickOutside(e) {
    if(this.element === e.target || this.element.contains(e.target)) return;

    if(!this.dropdownTarget.classList.contains("hidden")) {
      this.toggleDropdown()
    }
  }
}
