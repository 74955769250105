import { Controller as BaseController } from "@hotwired/stimulus"
import { datadogRum } from '@datadog/browser-rum'

export class Controller extends BaseController {
  static get targets() {
    return [ "attributes" ]
  }

  connect() {
    const datadogRumAttributes = JSON.parse(this.attributesTarget.dataset.datadogrumAttributes);

    datadogRum.init(datadogRumAttributes);
  }
}