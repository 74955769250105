import "./index.css"

import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  // TODO: resolve error loader to be able to call static targets = [ "dropdown"]
  static get targets() {
    return [ "dropdown", "arrow", "checkbox", "filtersCheckedCount" ]
  }

  connect() {
    this.setCount()
  }

  toggleDropdown() {
    event.preventDefault()
    this.dropdownTarget.classList.toggle("hidden")
    this.setCount()

    if (this.hasArrowTarget) {
      this.arrowTarget.classList.toggle("rotate-180")
    }
  }

  clickOutside(e) {
    if(this.element === e.target || this.element.contains(e.target)) return
    if(!this.dropdownTarget.classList.contains("hidden")) {
      this.toggleDropdown()
    }
  }

  setCount() {
    const checkboxesChecked = this.checkboxTargets.filter((c) => c.checked)
    this.displayCount(checkboxesChecked)
  }

  displayCount(checkboxesChecked) {
    if(checkboxesChecked.length >= 1) {
      this.filtersCheckedCountTarget.classList.remove("hidden")
      this.filtersCheckedCountTarget.innerHTML = `${checkboxesChecked.length}`
    } else {
      this.filtersCheckedCountTarget.classList.add("hidden")
    }
  }
}
