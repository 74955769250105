// Add a Stimulus controller for this component.
// It will automatically registered and its name will be available
// via #component_name in the component class.
//
import { Controller as BaseController } from "@hotwired/stimulus"
import {Datepicker} from 'flowbite-datepicker';

export class Controller extends BaseController {
  static get targets() {
    return [ "datepicker" ]
  }

  connect() {
    Datepicker.locales.fr = {
      fr: {
        days: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
        daysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        daysMin: ["D", "L", "M", "M", "J", "V", "S"],
        months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
        monthsShort: ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Juil", "Août", "Sept", "Oct", "Nov", "Dec"],
        today: "Aujourd'hui",
        monthsTitle: "Mois",
      }
    }

    const datepicker = new Datepicker(this.datepickerTarget, {
      autohide: true,
      format: 'dd/mm/yyyy',
      weekStart: 1,
      // TODO : apply locales fr
    });
  }
}
