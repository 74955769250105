import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "buttonList", "buttonGrid", "list", "grid" ]

  connect() {
    this.chooseView()
  }

  chooseView() {
    if (localStorage.getItem("showView") === 'list') {
      this.chooseList()
    } else {
      this.chooseGrid()
    }
  }

  chooseList() {
    this.buttonListTarget.classList.add('hidden')
    this.buttonGridTarget.classList.remove('hidden')
    this.listTarget.classList.remove('md:hidden')
    this.gridTarget.classList.add('hidden')
    localStorage.setItem("showView", "list")
  }

  chooseGrid() {
    this.buttonListTarget.classList.remove('hidden')
    this.buttonGridTarget.classList.add('hidden')
    this.listTarget.classList.add('md:hidden')
    this.gridTarget.classList.remove('hidden')
    localStorage.setItem("showView", "grid")
  }
}
